import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useEffect } from 'react';
import { useMobileApp } from '../hooks/useMobileApp';
import { getLocaleFromStaticContext } from '../utils/functions';

function Error404() {
  const { isAppView } = useMobileApp();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isAppView()) return;
      try {
        const script = document.createElement('script');
        script.innerHTML = `Intercom('showNewMessage');`;
        document.body.appendChild(script);
      } catch (err) {
        console.error(`Error appending intercom open script ${err}`);
      }
    }
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <main className="flex flex-col items-center justify-center h-screen text-black bg-white">
        <h1 className="text-vystemblue text-8xl">404</h1>
        <h2 className="text-base">{t('common:error-404')}</h2>
        <h3>{t('common:error-support')}</h3>
      </main>
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocaleFromStaticContext(context))),
    },
  };
};

export default Error404;
